export const getCompetencyCode = (subject) => {
    if (subject === "Anatomy") {
        return "AN"
    }
    if (subject === "Biochemistry") {
        return "BI"
    }
    if (subject === "Physiology") {
        return "PY"
    }
    if (subject === "Pathology") {
        return "PA"
    }
    if (subject === "Pharmacology") {
        return "PH"
    }
    if (subject === "Microbiology") {
        return "MI"
    }
    if (subject === "Foundation course" || subject === "Study Skills") {
        return "FC"
    }
    if (subject === "Forensic Medicine and Toxicology") {
        return "FM"
    }
    return ""
}