import React from 'react'
import ResultCard from "../../components/ResultCard/ResultCard";
import SearchIcon from '@mui/icons-material/Search';

const GridViewResults = ({ allResults }) => {
    return (
        allResults.length > 0 ?

            allResults.map((item, index) => {
                return <ResultCard key={index} item={item} style={{ margin: '10px', width: '20%', height: '160px' }} />
            })
            :
            <div className="_empty_results_section" style={{ width: '100%' }}>
                <h4 style={{ display: 'flex' }}>
                    <SearchIcon style={{ width: '20px', height: '20px' }} />
                    <p style={{ marginLeft: '10px' }}>Search for results</p>
                </h4>
            </div>
    )
}

export default GridViewResults