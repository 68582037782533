import React from "react";
import Card from '@mui/material/Card';
import { TextField } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import './SearchFieldCard.scss'

const SearchFieldCard = ({ label, cardStyle, placeholder, name, value, onChange, tooltip }) => {
    return (
        <Tooltip title={tooltip}>
            <Card
                className="_search_field_card_container"
                style={cardStyle}
            >
                <div style={{ padding: '10px' }} >
                    <TextField
                        name={name}
                        value={value}
                        placeholder={placeholder}
                        sx={{ width: '100%' }}
                        label={label}
                        variant="standard"
                        onChange={onChange}
                    />
                </div>
            </Card>
        </Tooltip>
    );
}

export default SearchFieldCard;