import React from "react";
import { Card, Button } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';

import './ResultCard.scss'
const ResultCard = ({ item, style }) => {
    return (
        <>
            <Card
                sx={{ margin: '10px 0px', height: '80px' }}
                elevation={2}
                className="_result_details_card_container"
                style={style}
            >
                <h4>{item && item.display_name}</h4>
                <p >{item.course_type == 1 ? "Digital Learning" : "Lecture Capsule"}</p>
                <Button
                    variant="outlined"
                    style={{
                        width: '150px',
                        fontWeight: 'bold',
                        padding: '0px 10px',
                        marginTop: 'auto',
                        textTransform: 'capitalize'
                    }}
                    endIcon={<LinkIcon fontSize="2" />}
                    onClick={() => {
                        window.open(item && item.links, "_blank")
                    }}
                >
                    Visit Link
                </Button>
            </Card>
        </>
    );
}

export default ResultCard;