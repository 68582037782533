import axios from './index'

export const getAllPY1Data = (inputs) => {
    return axios.get('/year_1/',
        {
            params: {
                course_type: inputs.course_type && inputs.course_type,
                organ: inputs.organ && inputs.organ,
                subject: inputs.subject && inputs.subject,
                competency_number: inputs.competency_number && inputs.competency_number,
                search: inputs.search && inputs.search,
                page_size: inputs.page_size && inputs.page_size
            },

        }
    )
        .then(res => res.data)
        .catch(e => e.response.data)
}

export const getAllPY2Data = (inputs) => {
    return axios.get('/year_2/',
        {
            params: {
                course_type: inputs.course_type && inputs.course_type,
                topic_name: inputs.topic_name && inputs.topic_name,
                subject: inputs.subject && inputs.subject,
                competency_number: inputs.competency_number && inputs.competency_number,
                search: inputs.search && inputs.search,
                page_size: inputs.page_size && inputs.page_size
            },

        }
    )
        .then(res => res.data)
        .catch(e => e.response.data)
}