export const validatePY1Search = (subject, pattern) => {

    if (subject === "Anatomy") {
        if (pattern.substring(0, 2) !== "AN") {
            return { error: true, pattern: "AN" }
        } else {
            return { error: false }
        }
    }
    if (subject === "Biochemistry") {
        if (pattern.substring(0, 2) !== "BI") {
            return { error: true, pattern: "BI" }
        } else {
            return { error: false }
        }
    }
    if (subject === "Physiology") {
        if (pattern.substring(0, 2) !== "PY") {
            return { error: true, pattern: "PY" }
        } else {
            return { error: false }
        }
    }
    return { error: false }
}

export const validatePY2Search = (subject, pattern) => {
    if (subject === "Pathology") {
        if (pattern.substring(0, 2) !== "PA") {
            return { error: true, pattern: "PA" }
        } else {
            return { error: false }
        }
    }
    if (subject === "Pharmacology") {
        if (pattern.substring(0, 2) !== "PH") {
            return { error: true, pattern: "PH" }
        } else {
            return { error: false }
        }
    }
    if (subject === "Microbiology") {
        if (pattern.substring(0, 2) !== "MI") {
            return { error: true, pattern: "MI" }
        } else {
            return { error: false }
        }
    }
    return { error: false }

}