import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import Pagination from '@mui/material/Pagination';
import { Tab, Tabs } from "@mui/material";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Swal from "sweetalert2";

import TopBar from "../../components/TopBar/TopBar";
import DropDown from "../../components/DropDown/DropDown";
import SearchFieldCard from "../../components/SearchFieldCard/SearchFieldCard";
import TabPanel from "../../components/TabPanel/TabPanel";
import ListViewResults from "../ListViewResults/ListViewResults";
import GridViewResults from "../GridViewResults/GridViewResults";

import { getAllSubjectOnes, getAllSubjectTwos, getAllTopics, getOneSubjectOne, getOneSubjectTwo, getOneOrgan, getAllOrgans, getOneTopic } from '../../services/axios/masterDataAPI'
import { getAllPY2Data, getAllPY1Data } from '../../services/axios/getRequests'
import yearData from '../../services/data/yearData'
import { getCompetencyCode } from "../../services/helper-functions/subject_to_competency_code";
import { validatePY1Search, validatePY2Search } from '../../services/helper-functions/validateSearch'

import './Home.scss'
import { ElevatorSharp } from "@mui/icons-material";

const Home = () => {

    const initial = {
        year: "",
        course_type: 1,
        organ: "",
        topic_name: "",
        subject: "",
        competency_number: "",
        search: "",
        page_size: 1000
    };
    const [inputs, setInputs] = useState(initial)
    const [gotResponse, setGotResponse] = useState(true)
    const [results, setResults] = useState([]);


    const [digitalLearningResults, setDigitalLearningResults] = useState([])
    const [digitalLearningTotalResultsCount, setDigitalLearningTotalResultsCount] = useState(0)

    const [lectureCapsuleResults, setLectureCapsuleResults] = useState([])
    const [lectureCapsuleTotalResultsCount, setLectureCapsuleTotalResultsCount] = useState(0)

    const [value, setValue] = useState(0);
    const [year, setYear] = useState(yearData)
    const [subjectName, setSubjectName] = useState('')
    const [subjects, setSubjects] = useState('')
    const [allSubjects, setAllSubjects] = useState('')
    const [organName, setOrganName] = useState('')
    const [organs, setOrgans] = useState('')
    const [allOrgans, setAllOrgans] = useState('')
    const [topicName, setTopicName] = useState('')
    const [topics, setTopics] = useState('')
    const [allTopics, setAllTopics] = useState('')
    const [page, setPage] = useState(1)
    const [viewType, setViewType] = useState('grid')
    const [allResults, setAllResults] = useState([])
    const [showingResults, setShowingResults] = useState([])
    const [allPageCount, setAllPageCount] = useState('')
    const [isSearched, setIsSearched] = useState(false)

    const competency_tooltip = "Search competency number"


    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        if (inputs.year === 1) {
            setSubjects([])
            setInputs({ ...inputs, competency_number: "" })
            if (!inputs.organ) {
                getAllOrgans().then(res => {
                    if (res.success) {

                        let organFilterArray = res.data.results.filter(item => {
                            if (inputs.year === 1) {
                                return item.year.id === 1 || item.year.id === 2
                            } else if (inputs.year === 2) {
                                return item.year.id === 3 || item.year.id === 4
                            }
                        })
                        setAllOrgans(organFilterArray)
                        let keys = ['name']
                        let finalOrganFilter = organFilterArray.filter(
                            (s => o =>
                                (k => !s.has(k) && s.add(k))
                                    (keys.map(k => o[k]).join('|'))
                            )
                                (new Set)
                        );

                        setOrgans(finalOrganFilter)
                    }
                })
            }
        }
        if (inputs.year === 2) {
            setSubjects([])
            setInputs({ ...inputs, organ: "", competency_number: "" })

            getAllSubjectTwos().then(res => {
                if (res.success) {

                    let subjectFilterArray = res.data.results.filter(item => {
                        if (inputs.year === 1) {
                            return item.year.id === 1 || item.year.id === 2
                        } else if (inputs.year === 2) {
                            return item.year.id === 3 || item.year.id === 4
                        }
                    })
                    setAllSubjects(subjectFilterArray)
                    let keys = ['name']
                    let finalSubjectFilter = subjectFilterArray.filter(
                        (s => o =>
                            (k => !s.has(k) && s.add(k))
                                (keys.map(k => o[k]).join('|'))
                        )
                            (new Set)
                    );

                    setSubjects(finalSubjectFilter)
                }
            })
        }

    }, [inputs.year])

    const fetchSubjectName = (id) => {
        if (inputs.year === 1) {
            getOneSubjectOne(id).then(res => {
                if (res.success) {
                    setSubjectName(res.data.name)
                }
            })
        } else {
            getOneSubjectTwo(id).then(res => {
                if (res.success) {
                    setSubjectName(res.data.name)
                }
            })
        }

    }

    const fetchOrganName = (id) => {
        getOneOrgan(id).then(res => {
            if (res.success) {
                setOrganName(res.data.name)
            }
        })
    }

    const fetchTopicName = (id) => {
        getOneTopic(id).then(res => {
            if (res.success) {
                setTopicName(res.data.name)
            }
        })
    }

    const findSubjectIDByName = (subjectName) => {
        return allSubjects.filter(item => `${item.name}` === `${subjectName}`)
    }

    const findOrganIDByName = (organName) => {
        return allOrgans.filter(item => `${item.name}` === `${organName}`)

    }

    const findTopicIDByName = (topicName) => {
        return allTopics.filter(item => `${item.name}` === `${topicName}`)

    }

    const handleOnChange = (e) => {

        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        })

        if (e.target.name === "subject") {
            fetchSubjectName(e.target.value)
        }

        if (e.target.name === "organ") {
            fetchOrganName(e.target.value)
        }

        if (e.target.name === "topic_name") {
            fetchTopicName(e.target.value)
        }
    }

    useEffect(() => {
        if (isSearched) {
            if (digitalLearningTotalResultsCount == 0 && lectureCapsuleTotalResultsCount == 0) {
                Swal.fire({
                    position: 'top-start',
                    icon: 'info',
                    title: 'No results found',
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        }
    }, [digitalLearningTotalResultsCount, lectureCapsuleTotalResultsCount])


    useEffect(() => {

        let tempResults = [...digitalLearningResults, ...lectureCapsuleResults]


        setAllResults(tempResults)

        if (viewType == 'grid') {
            setAllPageCount(Math.ceil(tempResults.length / 12))
            setShowingResults(tempResults.slice(12 * (page - 1), 12 * (page - 1) + 12))
        } else {
            setAllPageCount(Math.ceil(tempResults.length / 5))
            setShowingResults(tempResults.slice(5 * (page - 1), 5 * (page - 1) + 5))
        }

    }, [digitalLearningResults, lectureCapsuleResults])


    const fetchPY1Data = async (inputs, organs, subjects) => {

        if (organs.length > 1) {
            let courseType1Organ = organs.filter(item => `${item.year.course_type.id}` === "1")
            let courseType2Organ = organs.filter(item => `${item.year.course_type.id}` === "2")


            if (subjects.length > 1) {
                let courseType1Subject = subjects.filter(item => `${item.organ.year.course_type}` === "1")
                let courseType2Subject = subjects.filter(item => `${item.organ.year.course_type}` === "2")
                getAllPY1Data({ ...inputs, subject: courseType1Subject[0].id, organ: courseType1Organ[0].id, course_type: 1 }).then(res => {
                    setGotResponse(true)
                    if (res.success) {
                        setDigitalLearningTotalResultsCount(res.data.count)
                        setDigitalLearningResults(res.data.results)
                    }
                })
                getAllPY1Data({ ...inputs, subject: courseType2Subject[0].id, organ: courseType2Organ[0].id, course_type: 2, }).then(res => {
                    setGotResponse(true)
                    if (res.success) {
                        setLectureCapsuleTotalResultsCount(res.data.count)
                        setLectureCapsuleResults(res.data.results)
                    }
                })
            } else {
                getAllPY1Data({ ...inputs, organ: courseType1Organ[0].id, course_type: 1 }).then(res => {
                    setGotResponse(true)
                    if (res.success) {
                        setDigitalLearningTotalResultsCount(res.data.count)
                        setDigitalLearningResults(res.data.results)
                    }
                })
                getAllPY1Data({ ...inputs, organ: courseType2Organ[0].id, course_type: 2 }).then(res => {
                    setGotResponse(true)
                    if (res.success) {
                        setLectureCapsuleTotalResultsCount(res.data.count)
                        setLectureCapsuleResults(res.data.results)
                    }
                })
            }


        } else {
            getAllPY1Data({ ...inputs, course_type: 1 }).then(res => {
                setGotResponse(true)
                if (res.success) {
                    setDigitalLearningTotalResultsCount(res.data.count)
                    setDigitalLearningResults(res.data.results)
                }
            })
            getAllPY1Data({ ...inputs, course_type: 2 }).then(res => {
                setGotResponse(true)
                if (res.success) {
                    setLectureCapsuleTotalResultsCount(res.data.count)
                    setLectureCapsuleResults(res.data.results)
                }
            })
        }
    }
    const fetchPY2Data = (inputs, subjects, topics) => {
        if (subjects.length > 1) {
            let courseType1Subject = subjects.filter(item => `${item.year.course_type.id}` === "1")
            let courseType2Subject = subjects.filter(item => `${item.year.course_type.id}` === "2")

            if (topics.length > 1) {

                let courseType1Topic = topics.filter(item => `${item.subjects.year.course_type}` === "1")
                let courseType2Topic = topics.filter(item => `${item.subjects.year.course_type}` === "2")

                getAllPY2Data({ ...inputs, subject: courseType1Subject[0].id, topic_name: courseType1Topic[0].id, course_type: 1 }).then(res => {
                    setGotResponse(true)
                    if (res.success) {
                        setDigitalLearningTotalResultsCount(res.data.count)
                        setDigitalLearningResults(res.data.results)
                    }
                })
                getAllPY2Data({ ...inputs, subject: courseType2Subject[0].id, topic_name: courseType2Topic[0].id, course_type: 2 }).then(res => {
                    setGotResponse(true)
                    if (res.success) {
                        setLectureCapsuleTotalResultsCount(res.data.count)
                        setLectureCapsuleResults(res.data.results)
                    }
                })
            } else {

                getAllPY2Data({ ...inputs, subject: courseType1Subject[0].id, course_type: 1 }).then(res => {
                    setGotResponse(true)
                    if (res.success) {
                        setDigitalLearningTotalResultsCount(res.data.count)
                        setDigitalLearningResults(res.data.results)
                    }
                })
                getAllPY2Data({ ...inputs, subject: courseType2Subject[0].id, course_type: 2 }).then(res => {
                    setGotResponse(true)
                    if (res.success) {
                        setLectureCapsuleTotalResultsCount(res.data.count)
                        setLectureCapsuleResults(res.data.results)
                    }
                })
            }

        } else {

            getAllPY2Data({ ...inputs, course_type: 1 }).then(res => {
                setGotResponse(true)
                if (res.success) {
                    setDigitalLearningTotalResultsCount(res.data.count)
                    setDigitalLearningResults(res.data.results)
                }
            })
            getAllPY2Data({ ...inputs, course_type: 2 }).then(res => {
                setGotResponse(true)
                if (res.success) {
                    setLectureCapsuleTotalResultsCount(res.data.count)
                    setLectureCapsuleResults(res.data.results)
                }
            })
        }


    }

    useEffect(() => {
        setInputs({
            ...inputs,
            competency_number: getCompetencyCode(subjectName)
        })
        if (inputs.year === 2) {

            getAllTopics().then(res => {
                if (res.success) {
                    let topicFilterArray = res.data.results.filter(item => {
                        return item.subjects?.name === subjectName
                    })
                    setAllTopics(topicFilterArray)
                    let keys = ['name']
                    let finalTopicFilter = topicFilterArray.filter(
                        (s => o =>
                            (k => !s.has(k) && s.add(k))
                                (keys.map(k => o[k]).join('|'))
                        )
                            (new Set)
                    );

                    setTopics(finalTopicFilter)
                }
            })
        }
    }, [subjectName])

    useEffect(() => {
        if (inputs.year === 1) {
            getAllSubjectOnes().then(res => {
                if (res.success) {
                    let subjectFilterArray = res.data.results.filter(item => {
                        return item.organ.name === organName
                    })
                    setAllSubjects(subjectFilterArray)
                    let keys = ['name']
                    let finalSubjectFilter = subjectFilterArray.filter(
                        (s => o =>
                            (k => !s.has(k) && s.add(k))
                                (keys.map(k => o[k]).join('|'))
                        )
                            (new Set)
                    );

                    setSubjects(finalSubjectFilter)
                }
            })
        }
    }, [organName])


    const handleSubmit = () => {
        setPage(1)
        if (!inputs.year) {
            Swal.fire({
                position: 'top-start',
                icon: 'info',
                title: 'Please Select an Year!',
                showConfirmButton: false,
                timer: 2000
            })
        }
        if (inputs.year === 1) {
            let validatePY1 = validatePY1Search(subjectName, inputs.competency_number)
            if (!validatePY1.error) {
                setGotResponse(false)
                setIsSearched(true)
                if (inputs.organ && inputs.subject) {

                    let organs = findOrganIDByName(organName)
                    let subjects = findSubjectIDByName(subjectName)
                    fetchPY1Data(inputs, organs, subjects)
                }
                if (!inputs.organ) {

                    fetchPY1Data(inputs, [], [])
                }
                if (inputs.organ && !inputs.subject) {
                    let organs = findOrganIDByName(organName)
                    fetchPY1Data(inputs, organs, [])
                }
            } else {
                Swal.fire({
                    position: 'top-start',
                    icon: 'info',
                    title: `${subjectName} competency number starts with ${validatePY1.pattern}`,
                    showConfirmButton: true
                })
                setSubjectName('')

            }
        }
        if (inputs.year === 2) {
            let validatePY2 = validatePY2Search(subjectName, inputs.competency_number)
            if (!validatePY2.error) {
                setGotResponse(false)
                setIsSearched(true)
                let subjects = findSubjectIDByName(subjectName)
                if (inputs.topic_name) {
                    let topics = findTopicIDByName(topicName)
                    fetchPY2Data(inputs, subjects, topics)
                } else {
                    fetchPY2Data(inputs, subjects, [])
                }
            } else {
                Swal.fire({
                    position: 'top-start',
                    icon: 'info',
                    title: `${subjectName} competency number starts with ${validatePY2.pattern}`,
                    showConfirmButton: true
                })
                setSubjectName('')
            }

        }
    }

    const handleResetSearch = () => {
        setInputs(initial)
        setIsSearched(false)
        setSubjectName('')
        setSubjects('')
        setOrganName('')
        setTopicName('')
        setDigitalLearningResults([])
        setLectureCapsuleResults([])
        setDigitalLearningTotalResultsCount(-1)
        setLectureCapsuleTotalResultsCount(-1)
        setPage(1)
    }

    const handleOnPageChange = (event, value) => {
        if (viewType == 'grid') {

            setShowingResults(allResults.slice(12 * (value - 1), (12 * (value - 1) + 12)))
        } else {
            setShowingResults(allResults.slice(5 * (value - 1), (5 * (value - 1) + 5)))

        }
        setPage(value);
    }

    const handleViewChange = (view) => {
        setPage(1)
        if (view == 'grid') {
            setAllPageCount(Math.ceil(allResults.length / 12))
        } else {
            setAllPageCount(Math.ceil(allResults.length / 5))
        }
        setViewType(view)
    }


    return (
        <>
            <TopBar />
            <Box sx={{ width: '100%' }} className="_home_main_container">
                <Box className="_formContainer" >
                    < form action="">
                        <DropDown
                            isCompulsory={true}
                            name="year"
                            value={inputs.year}
                            label="Year"
                            menu={year && year}
                            onChange={handleOnChange}

                        />
                        {
                            inputs.year === 1 ?
                                <DropDown
                                    name="organ"
                                    value={inputs.organ}
                                    label="Organ"
                                    menu={organs && organs}
                                    onChange={handleOnChange}
                                    dependency="Please select a year"

                                /> : <></>
                        }
                        <DropDown
                            label="Subject"
                            name="subject"
                            value={inputs.subject}
                            menu={subjects && subjects}
                            onChange={handleOnChange}
                            dependency={inputs.year == 1 ? "Please select an organ" : "Please select an year"}

                        />
                        {

                            inputs.year === 2 ?
                                <DropDown
                                    name="topic_name"
                                    value={inputs.topic_name}
                                    label="Topic Name"
                                    menu={topics && topics}
                                    onChange={handleOnChange}
                                    dependency="Please select a subject"

                                />
                                : <></>
                        }
                        <SearchFieldCard
                            tooltip={competency_tooltip}
                            name="competency_number"
                            value={inputs.competency_number}
                            placeholder="Search competency number"
                            onChange={handleOnChange}
                        />

                        <SearchFieldCard
                            tooltip="Type any keywords"
                            name="search"
                            value={inputs.search}
                            placeholder="Search keywords, display name, links ..."
                            onChange={handleOnChange}
                        />
                        {
                            gotResponse ?
                                <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                    {
                                        <Button
                                            sx={{
                                                textTransform: 'capitalize',
                                                width: "50%",
                                                marginRight: "10px"
                                            }}
                                            variant="contained"
                                            startIcon={<SearchIcon />}
                                            onClick={handleSubmit}
                                        >
                                            Search
                                        </Button>

                                    }

                                    <Button
                                        sx={{
                                            textTransform: 'capitalize',
                                            width: "50%"
                                        }}
                                        variant="contained"
                                        startIcon={<RestartAltIcon />}
                                        onClick={handleResetSearch}
                                    >
                                        Reset Search
                                    </Button>
                                </Box>
                                : <div style={{ marginTop: '20px', textAlign: 'center' }}>
                                    <CircularProgress />
                                </div>

                        }

                    </form>
                </Box>
                <Box className="_resultContainer">

                    <div className="_results_section_container">
                        {/* <h3 style={{ color: '#2196f3' }}><SearchIcon style={{ verticalAlign: 'sub' }} /> {totalResultsCount && totalResultsCount} search results</h3> */}
                        {
                            isSearched ?

                                <>
                                    <div className="_result_cards_collection_container"
                                    >
                                        <Box style={{
                                            height: '75vh',
                                            overflowY: 'auto'
                                        }}>
                                            {
                                                viewType === 'list' ? <ListViewResults allResults={showingResults} /> :
                                                    viewType === 'grid' ?
                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                            <GridViewResults allResults={showingResults} />
                                                        </div> : <></>
                                            }
                                            {/* <Box style={{ position: 'absolute', top: "70%", right: '5%', textAlignLast: 'center' }}>
                                                <ExpandCircleDownIcon style={{ color: "#2196f3" }} />
                                                <p style={{ fontSize: '18px' }}>Scroll Down</p>
                                            </Box> */}


                                        </Box>
                                        <Box style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            width: '100%'
                                        }}>
                                            <Pagination
                                                sx={{ pt: 2 }}
                                                page={page}
                                                count={allPageCount}
                                                className="_pagination_container"
                                                color="primary"
                                                onChange={handleOnPageChange}
                                            />
                                            <Box style={{
                                                float: 'right',

                                            }}>
                                                <ViewListIcon
                                                    onClick={() => handleViewChange('list')}
                                                    name="list"
                                                    sx={{ mt: 2, ml: 2, color: '#2196f3', cursor: 'pointer' }}
                                                    style={viewType === 'list' ? { border: '1px solid #2196f3' } : {}}
                                                />
                                                <ViewComfyIcon
                                                    onClick={() => handleViewChange('grid')}
                                                    name="grid"
                                                    sx={{ mt: 2, ml: 2, color: '#2196f3', cursor: 'pointer' }}
                                                    style={viewType === 'grid' ? { border: '1px solid #2196f3' } : {}}
                                                />
                                            </Box>

                                        </Box>

                                    </div>
                                </>
                                : <div className="_empty_results_section" style={{ width: '100%' }}>
                                    <h4 style={{ display: 'flex' }}>
                                        <SearchIcon style={{ width: '20px', height: '20px' }} />
                                        <p style={{ marginLeft: '10px' }}>Search for results</p>
                                    </h4>
                                </div>
                        }
                    </div>


                </Box>
            </Box>
        </>
    )
}

export default Home;