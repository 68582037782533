import React from "react";
import logo from '../../assets/logo.png'
import './TopBar.scss'

const TopBar = () => {
    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="_logo_container">
                    <img src={logo} alt="logo" className="logo" />

                </div>
                <h4 style={{ marginLeft: '10px', color: 'white' }}>Learning Assets</h4>
                <div className="topRight">
                    {/* <div className="topbarIconContainer">
          <NotificationsNone />
          <span className="topIconBadge">2</span>
        </div>
        <div className="topbarIconContainer">
          <Language />
          <span className="topIconBadge">2</span>
        </div>
        <div className="topbarIconContainer">
          <Settings />
        </div> */}
                </div>
            </div>
        </div>
    )

}

export default TopBar