import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { toastRed } from "./reducers/toast_reducer";

export default createStore(
    combineReducers({
        toast: toastRed,
    }),
    composeWithDevTools(applyMiddleware(thunk))
);