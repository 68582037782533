const yearData = [
    {
        "id": 1,
        "name": "PY1",
    },
    {
        "id": 2,
        "name": "PY2",
    }
]

export default yearData